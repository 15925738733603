@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
header{
  font-family: "Poppins", serif;
}
.subheader{
    width: 100%;
    height: 50px;
    background-color: #FF3432;
   display: flex;
   justify-content: start;
   align-items: center;
      padding: 20px 100px;
}
.submenu{
    display: flex;
    justify-content:start;
    align-items: center;
    gap:50px;
}
.info-box{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
}
.info-box i{
    font-size: 20px;
     color: white;
}
.info-box span{
    color: white;
    font-size: 16px;
}

nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 100px;
}
.logo{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.logo img{
    width: 50px;
}
.logo h1{
    font-size:25px ;
    font-weight: 600;
}
ul{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}
ul li{
    font-size: 17px;
    font-weight: 600;
    color: #1f1e1e;
}
ul li button{
    background: linear-gradient(214.38deg, #ff8079 -2.24%, #ff1e1e 59.38%);
    padding: 12px 25px;
    font-size: 18px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}