@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #7a7f9d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #21295c;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #21295c;
}

/* Smooth slide animation */
.slide-in-left {
  transform: translateX(-100%);
}

.slide-in-right {
  transform: translateX(100%);
}

.slide-in-left-active,
.slide-in-right-active {
  transform: translateX(0%);
}

.transition-transform {
  transition: transform 0.3s ease-in-out;
}